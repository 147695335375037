h1 {
  font-family: 'Open Sans', sans-serif;
  color: #5d9732;
  font-style: italic;
  text-align: left;
  font-size: 42pt;
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 36pt;
  font-weight: 800;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.15); }

h2 {
  font-family: 'Open Sans', sans-serif;
  color: #3c4254;
  text-align: left;
  font-style: italic;
  margin: 0px;
  font-size: 32pt;
  line-height: 32pt;
  font-weight: 800;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.15); }

h3 {
  font-family: 'Open Sans', sans-serif;
  color: #3c4254;
  text-align: left;
  font-size: 14pt; }

p {
  font-family: 'Open Sans', sans-serif;
  color: #3c4254;
  text-align: left;
  font-size: 9pt; }

body {
  position: absolute;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-size: 100%;
  background-image: url("/img/good2go.jpg"); }
  @media screen and (max-width: 1023px) {
    body {
      background-size: inherit;
      height: auto;
      background-repeat: no-repeat;
      overflow-y: auto; } }

.wrapper {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: center;
  width: 100%; }
  @media screen and (max-width: 768px) {
    .wrapper {
      border-top: solid 6px #5d9732;
      padding-top: 0px;
      padding-bottom: 20px; } }
  @media only screen and (max-width: 1350px) {
    .wrapper .bubble {
      display: none !important; } }

.bubble {
  background-color: #fff;
  border-top: solid 2px #5d9732;
  width: 300px;
  float: right;
  margin-top: -360px;
  margin-right: 440px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
  .bubble .avatar {
    position: absolute;
    margin-left: 110px;
    margin-top: -40px; }
  .bubble:nth-of-type(2), .bubble:nth-of-type(4) {
    margin-top: -280px;
    margin-right: 60px; }

.bottomSection {
  background-color: #f8f8f8;
  border-top: solid 1px rgba(0, 0, 0, 0.05); }
  .bottomSection .container {
    display: inline-flex; }
    @media screen and (max-width: 768px) {
      .bottomSection .container {
        display: block;
        padding-top: 0px; } }
    .bottomSection .container .logoWrapper {
      margin: 45px; }
      .bottomSection .container .logoWrapper img {
        width: 300px; }
      @media screen and (max-width: 768px) {
        .bottomSection .container .logoWrapper {
          margin: 20px;
          width: calc(100% - 40px); }
          .bottomSection .container .logoWrapper img {
            width: 180px; } }
    .bottomSection .container .zipCode {
      padding-top: 25px; }
      @media screen and (max-width: 768px) {
        .bottomSection .container .zipCode {
          padding-top: 0px;
          text-align: center; }
          .bottomSection .container .zipCode h3 {
            font-size: 12pt;
            text-align: center; } }
      .bottomSection .container .zipCode .error-message {
        font-family: 'Open Sans', sans-serif;
        color: #3c4254;
        text-align: left;
        font-size: 14pt;
        padding: 10px;
        border: solid 2px red;
        border-radius: 10px;
        margin: 10px;
        text-align: center;
        display: block; }
      .bottomSection .container .zipCode .zipInput {
        flex: 1;
        white-space: nowrap; }
        @media screen and (max-width: 768px) {
          .bottomSection .container .zipCode .zipInput {
            white-space: normal;
            text-align: center; }
            .bottomSection .container .zipCode .zipInput input {
              padding-right: 20px !important;
              border-radius: 30px !important;
              border-bottom-left-radius: 0px !important;
              border-bottom-right-radius: 0px !important;
              width: calc(100% - 54px) !important; }
            .bottomSection .container .zipCode .zipInput button {
              text-align: center;
              width: calc(100% - 10px) !important;
              border-radius: 30px !important;
              border-top-left-radius: 0px !important;
              border-top-right-radius: 0px !important;
              margin-left: 0px !important; } }
        .bottomSection .container .zipCode .zipInput input {
          padding: 20px;
          padding-right: 70px;
          border: solid 1px rgba(0, 0, 0, 0.1);
          border-radius: 50px;
          font-family: 'Open Sans', sans-serif;
          font-size: 14pt;
          vertical-align: middle;
          outline: none; }
          .bottomSection .container .zipCode .zipInput input:focus {
            border: solid 3px rgba(0, 0, 0, 0.1);
            padding: 18px;
            padding-right: 68px; }
        .bottomSection .container .zipCode .zipInput button {
          border: none;
          outline: none;
          width: 140px;
          margin-left: -60px;
          background-image: linear-gradient(#b8e09b, #5d9732);
          border: solid 4px #5d9732;
          color: #fff;
          font-family: 'Open Sans', sans-serif;
          font-style: italic;
          font-size: 24pt;
          border-radius: 50px;
          padding: 8px;
          vertical-align: middle; }
          .bottomSection .container .zipCode .zipInput button:hover {
            cursor: pointer;
            filter: brightness(110%); }

.container {
  padding: 100px;
  width: 100%; }
  @media screen and (max-width: 768px) {
    .container {
      width: auto;
      padding: 30px; }
      .container h2 {
        font-size: 18pt;
        text-align: center;
        line-height: normal; }
      .container h1 {
        font-size: 28pt;
        text-align: center;
        line-height: normal; } }
  @media only screen and (min-width: 1350px) {
    .container {
      max-width: 1000px;
      margin-left: 50%;
      padding-top: 50px;
      padding-bottom: 50px;
      transform: translateX(-50%); } }

#content {
  font-weight: 300;
  font-size: 14pt;
  font-family: sans-serif;
  color: #a6a6d8;
  text-align: left;
  padding: 0  30px; }
